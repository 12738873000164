import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL_V2 } from '../../utils/api';
import { IOrderPaymentResult, IOrderPaymentType } from '../../types/order';
import i18n from 'i18next';

interface Guest {
  first_name: string;
  last_name: string;
}
interface ICity {
  code: string;
  city: string;
  city_en: string;
  country:{
    code: string,
    country: string,
    country_en: string
}
}

interface IOrderResponse {
  id: string;
  created_at:string;
  sum: string;
  phone: string;
  email: string;
  status: number;
  status_label: string;
  payment_status: number;
  payment_status_label: string;
  payment_method: string | null;
  date_from: string;
  date_to: string;
  persons_count: number;
  persons: Guest[];
  comment: string;
  locale: string | null;
  city: ICity;
  currency: string
}
export interface IOrderPayment {
  order_id: string;
  type: IOrderPaymentType;
}


const basicHeaders = { Accept: 'application/json', 'Content-type': 'application/json' };

export const searchCitiesApi = createApi({
  reducerPath: 'searchCitiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders: (headers) => {
      headers.set('Accept-Language', i18n.language);

      return headers;
    },
  }),
  tagTypes: ['Hotels'],
  endpoints: builder => ({
    searchHotels: builder.query<[], string>({
      query: s => ({ url: 'hotels/cities', params: { s } }),
      keepUnusedDataFor: 3600
    }),

        createPaymentHotel: builder.mutation<IOrderPaymentResult, IOrderPayment >({
      query: ({  order_id, type }) => {
        return {
          url: `/hotels/orders/${order_id}/payment`,
          method: 'POST',
          headers: { ...basicHeaders },
          body:{"payment_method": type},
          params: { order_id }
        };
      },
    }),


        getOrderSuccessPay: builder.query<IOrderResponse, { order_id?: number | string }>({
      query: ({  order_id }) => ({
        url: `/hotels/orders/${order_id}`,
        headers: { Accept: 'text/html' },
      }),
    }),



getOrder: builder.mutation<IOrderResponse, {
  city_code: string,
  date_from: string,
  date_to: string,
  persons_count: number,
  persons: Guest[],  // Массив гостей
  phone: string,
  email: string,
  comment: string,
  locale: string,
  currency: string,
  type_hotel: string
  wish_cost: string

}>({
  query: ({
    city_code,
    date_from,
    date_to,
    persons_count,
    persons,
    phone,
    email,
    comment,
    locale,
    currency,
    type_hotel,
    wish_cost
  }) => {
    const requestBody = {
      city_code,
      date_from,
      date_to,
      persons_count,
      persons,
      phone,
      email,
      comment,
      locale,
      currency,
      type_hotel,
      wish_cost
    };

    // Выводим данные в консоль

    return {
      url: 'hotels/orders',
      method: 'POST',
      headers: {
        ...basicHeaders
      },
      body: requestBody
    };
  }
})



  })
});

export const {
  useSearchHotelsQuery,
  useGetOrderMutation,
  useCreatePaymentHotelMutation,
  useGetOrderSuccessPayQuery
} = searchCitiesApi;
